import React, {useContext} from "react";
import {Badge} from "../../../ui/badge";
import {Checkbox} from "../../../ui/checkbox";
import {Button} from "../../../ui/button";
import {Check, ChevronDown, Eye, EyeOff} from "lucide-react";
import {StepContentView} from "./content/step-content";
import {StepBlueprint} from "tekkr-common/dist/model/playbook/step/blueprint";
import {StepEdit} from "tekkr-common/dist/model/playbook/step/edit";
import {Collapsible, CollapsibleContent} from "../../../ui/collapsible";
import {cn} from "../../../../lib/utils";
import {PlaybookPageContext} from "../../../../pages/playbook/playbook-page";
import {PlaybookViewControllerContext} from "../playbook-view";

export default function PlaybookStep(props: { step: StepBlueprint, index: number, stepEdit?: StepEdit, isEditing: boolean }) {
    const [done, setDoneState] = React.useState(props.stepEdit?.done);
    const [hidden, setHiddenState] = React.useState(props.stepEdit?.hidden);
    const [expanded, setExpanded] = React.useState(false);

    const { controller: pageController } = useContext(PlaybookPageContext);
    const viewController = useContext(PlaybookViewControllerContext);

    const edit = props.stepEdit;

    if (edit && !edit.content) {
        edit.content = {};
    }
    function setDone(done: boolean) {
        if (!edit) {
            return;
        }
        edit.done = done;
        pageController.editUpdated();
        setDoneState(done);
    }
    function setHidden(hidden: boolean) {
        if (!edit) {
            return;
        }
        edit.hidden = hidden;
        pageController.editUpdated();
        setExpanded(false);
        setHiddenState(hidden);
    }

    function completeStepEdit() {
        setDone(true);
        setExpanded(false);
        setTimeout(() => viewController.goToStep(props.index + 1), 100);
    }

    return (
        <>
            <div style={{scrollMarginTop: "56pt"}} className={"rounded-lg border border-accent relative bg-background"}>
                <div className={"overflow-hidden relative rounded-lg"}>
                    <div className={cn("flex flex-col p-5 gap-4", hidden ? "opacity-45" : null)}>
                        <div className={"flex flex-row justify-between"}>
                            <Badge variant={"mini_accent"}
                                   className={done && !hidden ? "bg-confirmation text-confirmation-foreground" : undefined}>{props.step.name}</Badge>
                            {props.isEditing ?
                                <div className={cn("flex items-center space-x-2", hidden ? "hidden" : null)}>
                                    <Checkbox variant={done ? "confirmation" : "primary"}
                                              id={`done-step-${props.step.id}`}
                                              checked={done} onCheckedChange={(done) => setDone(!!done)}/>
                                    <label htmlFor={`done-step-${props.step.id}`}
                                           className=" text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                        done
                                    </label>
                                </div> : null}
                        </div>
                        <h3 className={cn("headline-h3", !hidden ? "cursor-pointer" : null)}
                            onClick={() => setExpanded(!expanded)}>
                            {props.step.title}
                            <Button className={cn("w-6 h-6 p-1 ml-2", hidden ? "hidden" : null)}
                                    style={{verticalAlign: "middle"}} variant={"ghost"}
                                    size={"icon"}>
                                <ChevronDown className={"transition-all"}
                                             style={{transform: expanded ? "rotate(-180deg)" : undefined}}/>
                            </Button>
                        </h3>
                        <Collapsible open={expanded && !hidden} onOpenChange={setExpanded}>
                            <CollapsibleContent className="space-y-2">
                                <hr className={"-mx-10 border-0 border-b border-b-accent"}/>
                                <div className={"flex flex-col gap-2"}>
                                    {props.step.content.map((content, index) => {
                                        if ("id" in content && edit && !edit.content![content.id]) {
                                            edit.content![content.id] = {};
                                        }
                                        return <StepContentView key={index} step={props.step} content={content}
                                                                isEditing={props.isEditing}
                                                                contentEdit={"id" in content && edit ? edit.content?.[content.id] : undefined}/>
                                    })}
                                </div>
                            </CollapsibleContent>
                        </Collapsible>
                    </div>
                    <div
                        className={cn("w-1 top-0 bottom-0 absolute", hidden ? "bg-secondary" : (done ? "bg-confirmation" : "bg-primary"))}></div>
                </div>
                {props.isEditing ? <div className={"w-12 absolute -right-14 top-0 flex flex-col"}>
                    <Button onClick={() => setHidden(!hidden)} size={"sm"} variant={"secondary"}
                            className={"w-8 h-8 p-2 opacity-65"}>{hidden ? <Eye/> : <EyeOff/>}</Button>
                </div> : null}
            </div>
            { props.isEditing && expanded ? <Button onClick={() => completeStepEdit()} variant={"ghost"}><Check className={"w-5 h-5 me-2"} />Complete &quot;{props.step.title}&quot;</Button> : null }
        </>
    );
}