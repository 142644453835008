import {PlaybookBlueprint} from "tekkr-common/dist/model/playbook/blueprint";
import {
    ExternalLinkContentBlueprint
} from "tekkr-common/dist/model/playbook/step/content/types/external-link/blueprint";
import {Link} from "react-router-dom";
import { Link as LinkIcon } from "lucide-react";

export default function ExternalLinkStepContentView (props: { step: PlaybookBlueprint["steps"][0], content: ExternalLinkContentBlueprint }) {
    return <Link target={"_blank"} to={props.content.link}>
        <div className={"border border-accent rounded-md px-4 py-3 grid grid-cols-3 items-center justify-between mt-6 space-x-4 relative"}>
            <img alt={props.content.title} className={"aspect-3/2 rounded-md overflow-hidden bg-accent"} />
            <div className={"col-span-2 pe-8"}>
                <h4 className={"headline-h4"}>{ props.content.title }</h4>
                { !!props.content.description ? <p>{ props.content.description }</p> : null }
            </div>
            <LinkIcon className={"absolute top-4 right-4 w-4 h-4 opacity-65"} />
        </div>
    </Link>
}