import {HttpOrg} from "tekkr-common/dist/model/api/org";
import React, {createContext, useContext} from "react";
import {apiClient} from "../service/tekkr-service";
import {Navigate} from "react-router-dom";
import {useAuth} from "./auth-provider";
import {useQuery} from "@tanstack/react-query";
import {ClientInferResponseBody} from "@ts-rest/core";
import {apiContract} from "tekkr-common/dist/model/api/api.contract";

const OrgContext = createContext<OrgController>({} as OrgController);
export const useOrg = (): HttpOrg => {
    const controller = useContext(OrgContext);
    if (!controller?.org) {
        throw new Error("No org available.");
    }
    return controller.org;
}

interface OrgController {
    refetch: () => Promise<any>;
    org: HttpOrg | null;
}
export const useOrgController = (): OrgController => {
    return useContext(OrgContext);
}

const orgQuery = (orgId: string | null) => ({
    queryKey: ["org", orgId],
    queryFn: async () => {
        if (!orgId) {
            return null;
        }
        const response = await (await apiClient).getOrgById({ params: { orgId } });
        return response.body as ClientInferResponseBody<typeof apiContract.getOrgById, 200>;
    }
});

export const OrgProvider = ({ children }: React.PropsWithChildren) => {
    const auth = useAuth();

    const orgId = auth.account.orgs[0]?.id;
    const { isPending, data, refetch } = useQuery(orgQuery(orgId));

    return (
        <OrgContext.Provider value={{
            org: data ?? null,
            refetch,
        }}>
            { !isPending ? children : undefined }
        </OrgContext.Provider>
    );
};
export const RequireOrg = (props: React.PropsWithChildren) => {
    const controller = useContext(OrgContext);
    if (!controller.org) {
        console.log("no organization");
        return <Navigate to={{ pathname: "/no-organization" }} replace />
    }
    return <>{ props.children }</>;
};