import {Check, Edit, Save, TriangleAlert} from "lucide-react";
import BackButtonRow from "../../components/shared/back-button-row";
import React, {createContext, useEffect} from "react";
import {ContentSpacer} from "../common/layout";
import {apiClient} from "../../service/tekkr-service";
import {useQuery} from "@tanstack/react-query";
import Spinner from "../../components/ui/spinner";
import {PlaybookEdit} from "tekkr-common/dist/model/playbook/edit";
import {delay} from "../../lib/utils";
import {ClientInferResponseBody} from "@ts-rest/core";
import {apiContract} from "tekkr-common/dist/model/api/api.contract";
import {PlaybookBlueprint} from "tekkr-common/dist/model/playbook/blueprint";
import PlaybookView from "../../components/shared/playbook-view/playbook-view";
import {useLocation, useParams} from "react-router-dom";
import debounce from 'debounce';
import {Button} from "../../components/ui/button";
import {PlaybookHeader} from "../../components/shared/playbook-view/playbook-header";
import {useOrg} from "../../auth/org-provider";

export interface PlaybookPageNavigationState {
    openEditMode?: boolean;
}

const getPlaybookQuery = (playbookId: string) => ({
    queryKey: [`playbook-${playbookId}-get`],
    queryFn: async () => {
        const playbookResponse = await (await apiClient).getPlaybookById({ params: { playbookId } });
        const playbook = (playbookResponse.body as ClientInferResponseBody<typeof apiContract.getPlaybookById, 200>).data;
        const blueprintResponse = await (await apiClient).getBlueprintById({ params: { id: playbook.blueprintId } });
        const blueprint = (blueprintResponse.body as ClientInferResponseBody<typeof apiContract.getBlueprintById, 200>) as PlaybookBlueprint;
        return { playbook, blueprint };
    }
});


const updatePlaybookEditQuery = (playbookId: string, edit?: PlaybookEdit) => ({
    // todo use mutation here
    queryKey: [`playbook-${playbookId}-update-edit`],
    queryFn: async () => {
        if (!edit) throw new Error("missing edit");
        const start = Date.now();
        await (await apiClient).updatePlaybookEdit({
            params: { playbookId },
            body: edit,
        });
        // wait if too quick
        if (Date.now() - start < 100) {
            await delay(200 - (Date.now() - start));
        }
        return true;
    },
    enabled: !!edit,
});

export interface PlaybookPageController {
    editUpdated: () => void;
}

interface PlaybookPageContextType {
    controller: PlaybookPageController;
}

const PlaybookPageContext = createContext<PlaybookPageContextType>({} as PlaybookPageContextType);

function PlaybookPage(props: any) {
    const { playbookId } = useParams();
    if (!playbookId) {
        throw new Error("missing playbook id");
    }

    const location = useLocation();
    const navState = location.state as PlaybookPageNavigationState | undefined;
    const org = useOrg();

    const [isEditing, setIsEditing] = React.useState(navState?.openEditMode ?? false);
    const {isPending, isError, error, data} = useQuery(getPlaybookQuery(playbookId));

    useEffect(() => {
        document.title = `${data?.playbook?.edit?.title ?? data?.blueprint.title ?? "Playbook"} - Tekkr`
    }, [data]);

    const updateQuery = useQuery(updatePlaybookEditQuery(playbookId, data?.playbook.edit));

    let content = <div className={"w-full flex flex-col items-center"}>
        <Spinner className={"w-8 h-8"}></Spinner>
        <div className={"mt-4 text-sm text-secondary-foreground"}>Loading...</div>
    </div>
    if (isError) {
        content = <div>error: {error.message}</div>
    } else if (!isPending) {
        const playbookEdit = data.playbook.edit as PlaybookEdit;

        if (!playbookEdit.steps) {
            playbookEdit.steps = {};
        }

        const pageContext = {
            controller: {
                editUpdated: debounce(() => {
                    void updateQuery.refetch();
                }, 500),
            },
        };

        content = <>
            <PlaybookPageContext.Provider value={pageContext}>
                <PlaybookHeader blueprint={data?.blueprint} playbookEdit={playbookEdit}>
                    <code>[created by { org.users.find((u) => u.id === data?.playbook.createdBy)!.name }]</code>
                </PlaybookHeader>
                <PlaybookView blueprint={data?.blueprint} playbookEdit={playbookEdit} isEditing={isEditing} />
                <div className={"fixed top-0 right-24 flex flex-row items-center h-16 w-50 z-50"}>
                    { isEditing ? <div
                        className={"rounded-md px-4 py-1.5 border-accent border-2 text-accent-foreground font-semibold text-sm mx-3"}>
                        <div className={"opacity-65"}>
                            {updateQuery.isFetching ? <div className={"flex flex-row gap-2 items-center"}><Spinner
                                className={"w-5 h-5"}></Spinner> saving changes</div> : null}
                            {!updateQuery.isError && !updateQuery.isFetching ?
                                <div className={"flex flex-row gap-2 items-center"}><Save
                                    className={"w-5 h-5"}/> changes saved
                                </div> : null}
                            {updateQuery.isError ?
                                <div className={"flex flex-row gap-2 items-center"}><TriangleAlert/> Changes could not
                                    be saved! <u><a href={"mailto:support@tekkr.io"}>Contact us.</a></u></div> : null}
                        </div>
                    </div> : null }
                    { isEditing ?
                        <Button size={"sm"} onClick={() => setIsEditing(false)}><Check className={"w-5 h-5"} /><span className={"ms-2"}>Done Editing</span></Button>
                        : <Button size={"sm"} className={"px-4"} onClick={() => setIsEditing(true)}><Edit className={"w-5 h-5"} /><span className={"ms-2"}>Edit</span></Button>
                    }
                </div>
            </PlaybookPageContext.Provider>
        </>
    }

    return (
        <div className={"px-8 py-6"}>
            <BackButtonRow></BackButtonRow>
            <ContentSpacer contentWidth={"narrower"}>
                {content}
            </ContentSpacer>
        </div>
    )
}

export {PlaybookPageContext};

export default PlaybookPage;