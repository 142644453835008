import {ContentSpacer} from "../common/layout";
import {useQuery} from "@tanstack/react-query";
import {Link, useParams} from "react-router-dom";
import {apiClient} from "../../service/tekkr-service";
import {PlaybookBlueprint} from "tekkr-common/dist/model/playbook/blueprint";
import LoadingCard from "../../components/shared/loading-card";
import PlaybookView from "../../components/shared/playbook-view/playbook-view";
import {Button} from "../../components/ui/button";
import {ArrowRight, Edit, Library} from "lucide-react";
import React, {useEffect} from "react";
import CreatePlaybookDialog from "../../modals/create-playbook-dialog";
import {DialogTrigger} from "../../components/ui/dialog";
import BackButtonRow from "../../components/shared/back-button-row";
import {PlaybookHeader} from "../../components/shared/playbook-view/playbook-header";
import {useOrg} from "../../auth/org-provider";
import {ClientInferResponseBody} from "@ts-rest/core";
import {apiContract} from "tekkr-common/dist/model/api/api.contract";
import {DateTime} from "luxon";

type LibraryType = ClientInferResponseBody<typeof apiContract.listPlaybooks, 200>["data"]

function LibraryBox (props: { library: LibraryType }) {
    return <div className={"my-4"}>
        <div className={"flex flex-row items-center text-primary gap-1"}>
            <Library/>
            <h4 className={"headline-h4"}>Your Library</h4>
        </div>
        <p className={"text-sm mt-1"}>You have { props.library.length.toString() } copies of this playbook in your library:</p>
        { props.library.map((pb) => <Button key={pb.id}  variant={"outline"} className={"w-full mt-2"} asChild>
            <Link to={`/playbook/${pb.id}`}>
                <div className={"w-full flex flex-row items-center justify-between gap-3"}>
                    <p className={"shrink overflow-ellipsis overflow-hidden"}>{pb.title}</p>
                    <div className={"flex flex-row items-center gap-2 shrink-0"}>
                        <div className={"shrink-0 text-sm text-muted-foreground"}>created
                            on {DateTime.fromISO(pb.createdAt).toLocaleString({
                                month: "short",
                                day: "numeric",
                                year: "numeric"
                            })}</div>
                        <ArrowRight className={"shrink-0 w-5 h-5"}/>
                    </div>
                </div>
            </Link>
        </Button>)}
    </div>;

}

export default function BlueprintPage() {
    const {blueprintId} = useParams();
    if (!blueprintId) {
        throw new Error("blueprint id missing");
    }
    const blueprintQuery = useQuery({
        queryKey: ["blueprint", blueprintId],
        queryFn: async () => {
            const response = await (await apiClient).getBlueprintById({params: {id: blueprintId}});
            return response.body as PlaybookBlueprint;
        },
    });

    const org = useOrg();
    const libraryQuery = useQuery({
        queryKey: ["playbooks", "by-blueprint", blueprintQuery.data?.id],
        queryFn: async () => {
            const response = await (await apiClient).listPlaybooks({ query: { org: org.id, blueprintId: blueprintQuery.data!.id } });
            return (response.body as ClientInferResponseBody<typeof apiContract.listPlaybooks, 200>).data;
        },
        enabled: !!blueprintQuery.data,
    })
    
    useEffect(() => {
        document.title = `${blueprintQuery.data?.title ?? "Playbook"} - Tekkr`
    }, [blueprintQuery.data?.title]);

    // todo error state
    return <>
            {blueprintQuery.isPending ?
                <ContentSpacer><LoadingCard/></ContentSpacer>
                :
                <div className={"px-8 py-6"}>
                    <BackButtonRow></BackButtonRow>
                    <ContentSpacer contentWidth={"narrower"}>
                        <PlaybookHeader blueprint={blueprintQuery.data!}>
                            { !!libraryQuery.data && libraryQuery.data.length > 0 ? <LibraryBox library={libraryQuery.data} /> : null }
                        </PlaybookHeader>
                        <PlaybookView blueprint={blueprintQuery.data!} isEditing={false}/>
                    </ContentSpacer>
                </div>
        }
{
    !!blueprintQuery.data ? <>
        <div className={"h-32"}></div>
        <div
            className={"fixed p-4 w-full bg-primary bottom-0 z-40 text-primary-foreground flex flex-row items-center justify-center gap-12"}>
                <div className={"flex flex-col max-w-lg gap-0.5"}>
                    <h3 className={"headline-h3"}>Edit this playbook to make it your own!</h3>
                    <p>Tekkr editing makes it easy to tailor this playbook to your team.</p>
                </div>
                <CreatePlaybookDialog blueprint={blueprintQuery.data!}>
                    <DialogTrigger asChild>
                        <Button variant={"outline-primary-foreground"}>
                            <Edit className={"w-5 h-5"}/>
                            <span className={"font-semibold ms-2"}>Start editing</span>
                        </Button>
                    </DialogTrigger>
                </CreatePlaybookDialog>
            </div>
        </> : null}
    </>
}