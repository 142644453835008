"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectSubjectConfigs = exports.SelectContentSubject = void 0;
var SelectContentSubject;
(function (SelectContentSubject) {
    SelectContentSubject["meetingDuration"] = "meeting_duration";
    SelectContentSubject["meetingCadence"] = "meeting_cadence";
    SelectContentSubject["workingMode"] = "working_mode";
    SelectContentSubject["location"] = "location";
})(SelectContentSubject || (exports.SelectContentSubject = SelectContentSubject = {}));
exports.selectSubjectConfigs = {
    [SelectContentSubject.meetingDuration]: {
        title: "Meeting Duration",
        options: [
            { id: "15min", displayName: "15 Minutes" },
            { id: "30min", displayName: "30 Minutes" },
            { id: "45min", displayName: "45 Minutes" },
            { id: "60min", displayName: "60 Minutes" },
            { id: "90min", displayName: "90 Minutes" },
        ],
    },
    [SelectContentSubject.workingMode]: {
        title: "Working Mode",
        options: [
            { id: "asynchronous", displayName: "Asynchronous" },
            { id: "meeting_in_person", displayName: "Meeting (in person)" },
            { id: "meeting_virtual", displayName: "Meeting (virtual)" },
        ],
    },
    [SelectContentSubject.meetingCadence]: {
        title: "Meeting Cadence",
        options: [
            { id: "weekly", displayName: "Every week" },
            { id: "two_weeks", displayName: "Every two weeks" },
            { id: "three_weeks", displayName: "Every three weeks" },
            { id: "monthly", displayName: "Every month" },
            { id: "quarterly", displayName: "Quarterly" },
        ],
    },
    [SelectContentSubject.location]: {
        title: "Location",
        options: [
            { id: "in_person", displayName: "Physical / In Person" },
            { id: "remote", displayName: "Remote" },
        ],
    }
};
