import PlaybookStats from "./components/playbook-stats";
import React from "react";
import {Badge} from "../../ui/badge";
import {PlaybookBlueprint} from "tekkr-common/dist/model/playbook/blueprint";
import {PlaybookEdit} from "tekkr-common/dist/model/playbook/edit";
import {PlaybookCategory, playbookCategoryConfig} from "tekkr-common/dist/model/playbook/enums/playbook-category";

interface Props {
    blueprint: PlaybookBlueprint;
    playbookEdit?: PlaybookEdit
}

function CategoryTagsBar(props: { categories: PlaybookCategory[] }) {
    return (
        <div className={"flex flex-row justify-between w-full items-center"}>
            <div className={"flex flex-row gap-2 flex-wrap"}>
                { props.categories.map((category) => {
                    const config = playbookCategoryConfig[category];
                    return <Badge key={category} style={{backgroundColor: config.color}}>{ config.title }</Badge>
                } )}
            </div>
        </div>
    )
}

export function PlaybookHeader(props: React.PropsWithChildren & Props) {
    const computeProgress = (): number | undefined => {
        if (!props.playbookEdit) {
            return undefined;
        }
        let count = 0, done = 0;
        for (const step of Object.values(props.playbookEdit.steps ?? {})) {
            if (step.hidden) continue;
            count += 1;
            done += step.done ? 1 : 0;
        }
        return done / count;
    }
    return <div className={"flex flex-col gap-4"}>
        <CategoryTagsBar categories={props.blueprint.categories}/>
        <h1 className={"headline-h1"}>{props.playbookEdit?.title ?? props.blueprint.title}</h1>
        <PlaybookStats progress={computeProgress()} pb={props.blueprint}></PlaybookStats>
        { props.children }
        <img alt={"Playbook Cover"} className={"border-0 rounded-lg object-cover max-h-[45vh]"}
             src={props.blueprint.imageUrl}/>
        <hr className={"my-6"}/>
    </div>
}