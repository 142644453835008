import { Copyright } from "lucide-react";
import {Button} from "../ui/button";

function Footer () {
    return (
        <div className={"w-full px-8 py-3 flex justify-between opacity-45 mt-6"}>
            <div className={"flex gap-3 items-center"}>
                <Copyright className={"w-3 h-3"}/>
                <div className={"text-xs"}>Tekkr GmbH</div>
            </div>
            <div className={"flex gap-3 items-center"}>
                <Button asChild variant={"ghost"} size={"sm2"}>
                    <a href={"mailto:support@tekkr.io"}>Contact Us</a>
                </Button>
                <Button variant={"ghost"} size={"sm2"}>Terms of Service</Button>
                <Button variant={"ghost"} size={"sm2"}>Privacy Policy</Button>
            </div>
        </div>
    )
}

export default Footer;