/*

 */

import {PlaybookBlueprint} from "tekkr-common/dist/model/playbook/blueprint";
import {ContentEdit} from "tekkr-common/dist/model/playbook/step/content/edit";
import {
    SelectContentBlueprint,
    selectSubjectConfigs
} from "tekkr-common/dist/model/playbook/step/content/types/select/blueprint";
import React, {useContext, useState} from "react";
import {
    Select,
    SelectContent,
    SelectGroup, SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "../../../../../ui/select";
import {SelectContentEdit} from "tekkr-common/dist/model/playbook/step/content/types/select/edit";
import {PlaybookPageContext} from "../../../../../../pages/playbook/playbook-page";

export default function SelectStepContentView(props: { step: PlaybookBlueprint["steps"][0], content: SelectContentBlueprint, contentEdit?: ContentEdit }) {
    const config = selectSubjectConfigs[props.content.subject];

    const edit = props.contentEdit as SelectContentEdit | undefined;

    const { controller } = useContext(PlaybookPageContext);

    const [selected, setSelected] = useState(edit?.selected ?? props.content.default);

    function onSelected(selected: string) {
        if (edit) {
            setSelected(selected);
            edit.selected = selected;
            controller.editUpdated();
        }
    }

    return (
        <div className={"flex flex-row mt-4"}>
            <div className={"me-4 align-top py-2 font-semibold flex-shrink-0"}>{ props.content.overrides?.title ?? config.title }:</div>
            <div>
                <Select onValueChange={(v) => onSelected(v)} value={selected}>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder={"Select"} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectLabel>{ props.content.overrides?.title ?? config.title }</SelectLabel>
                            { (config.options).map((op) => <SelectItem key={op.id} value={op.id}>{op.displayName}</SelectItem>)}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
        </div>
    )
}