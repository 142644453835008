import {Checkbox} from "../../../../../ui/checkbox";
import React, {useContext, useState} from "react";

import {useIdentifierList} from "../../../../../hooks/set-list";
import {PlaybookBlueprint} from "tekkr-common/dist/model/playbook/blueprint";
import {ChecklistContentBlueprint} from "tekkr-common/dist/model/playbook/step/content/types/checklist/blueprint";
import {ContentEdit} from "tekkr-common/dist/model/playbook/step/content/edit";
import {ChecklistContentEdit} from "tekkr-common/dist/model/playbook/step/content/types/checklist/edit";
import {PlaybookPageContext} from "../../../../../../pages/playbook/playbook-page";
import {RadioGroup, RadioGroupItem} from "../../../../../ui/radio-group";
import { Button } from "../../../../../ui/button";
import {X, Link as LinkIcon} from "lucide-react";
import {Link} from "react-router-dom";
import TekkrMarkdown from "../../../../tekkr-markdown";

type Item = ChecklistContentBlueprint["items"][0];

function ListItemContent(props: { item: Item }) {
    return <div>
        <div className={"flex flex-col items-start"}>
            {props.item.link ?
                <Link target={"_blank"} to={props.item.link}>
                    <div><span className={"underline"}>{props.item.title}</span><LinkIcon className={"inline w-3 h-3 ms-2 opacity-65"} /></div>
                </Link>
            : <div>{props.item.title}</div> }

            {props.item.clarify?.notes ? <TekkrMarkdown className={"text-muted-foreground text-sm"} markdown={props.item.clarify.notes} /> : null }
        </div>
    </div>
}

function CustomItemInput(props: { onAddItem: (name: string) => void, mode: "radio" | "box" }) {
    const [title, setTitle] = useState("");
    const addItem = (title: string) => {
        props.onAddItem(title.trim());
        setTitle("");
    }
    return <div className={"ps-2 space-x-2 flex flex-row items-center"}>
        { props.mode === "box" ? <Checkbox checked={false} className={"mt-1"} disabled={true}/> : null }
        { props.mode === "radio" ? <RadioGroupItem disabled={true} key={"$$input$$"} value={"$$input$$"}/> : null}
        <input className={"!outline-none min-w-56 max-w-full bg-background border-b border-b-accent ms-1.5"} value={title} onChange={(e) => setTitle(e.target.value)}
               placeholder={"add item"}></input>
        <Button className={"ms-1.5 text-sm"} size={"sm2"} variant={"outline"} disabled={title.trim().length < 2} onClick={() => addItem(title)}>add</Button>
    </div>;
}

export default function ListStepContentView(props: {
    step: PlaybookBlueprint["steps"][0],
    content: ChecklistContentBlueprint,
    contentEdit?: ContentEdit,
    isEditing: boolean
}) {
    const edit = props.contentEdit as ChecklistContentEdit | undefined;

    const {controller} = useContext(PlaybookPageContext);

    const [selected, add, remove, replace] = useIdentifierList(edit && "selectedItems" in edit ? edit.selectedItems : [], (selected) => {
        if (edit) {
            edit.selectedItems = selected;
            controller?.editUpdated();
        }
    });
    const [customItems, setCustomItems] = useState(edit?.customItems ?? []);

    const head = <div className={"mb-1"}>
        {props.content.title ? <h4 className={"headline-h4"}>{props.content.title}</h4> : null}
        {props.content.prompt ? <p>{props.content.description}</p> : null}
    </div>

    function addCustomItem(title: string) {
        if (edit) {
            edit.customItems = [...(edit.customItems ?? []), { title }];
            setCustomItems(edit.customItems);
            controller.editUpdated();
        }
    }
    function removeCustomItem(index: number) {
        if (edit && edit.customItems) {
            edit.customItems = [...edit.customItems.slice(0, index), ...edit.customItems.slice(index + 1)];
            setCustomItems(edit.customItems);
            controller.editUpdated();
        }
    }

    if (!props.isEditing) {
        const items = props.content.items;
        if (items.length === 0) {
            return <></>;
        }
        let selectedItems = items;
        if (edit) {
            selectedItems = items.filter(i => selected.includes(i.id));
        }
        const isSingleModeCustom = props.content.singleMode && (edit?.customItems?.length ?? 0) > 0;
        if (isSingleModeCustom) {
            selectedItems = [];
        }
        return <div>
            { head }
            <ul>
                {selectedItems.map((item) => <li key={item.id} className={"ps-2 flex flex-row mt-1"}>
                    &bull;&nbsp;&nbsp;
                    <ListItemContent item={item} />
                </li>)}
                { edit?.customItems?.map((item, index) => <li key={index} className={"ps-2 flex flex-row mt-1"}>
                    &bull;&nbsp;&nbsp;
                    <ListItemContent item={item as Item}/>
                </li>)}
            </ul>
        </div>
    } else {
        const options = props.content.singleMode ?
            <RadioGroup value={customItems.length > 0 ? "$$custom$$" : selected[0]} onValueChange={(e) => replace([e])} className={"mt-2"}>
                {props.content.items.map((item) => <div className="flex items-center space-x-2 ms-2">
                    <RadioGroupItem disabled={customItems.length > 0} key={item.id} value={item.id} />
                    <ListItemContent item={item} />
                </div>)}
                { edit?.customItems?.map((item) => <div className="flex items-center space-x-2 ms-2">
                    <RadioGroupItem key={"$$custom$$"} value={"$$custom$$"}/>
                    <ListItemContent item={item as Item}/>
                    <Button onClick={() => removeCustomItem(0)} variant={"ghost"} className={"w-6 h-6 p-0.5"}><X /></Button>
                </div>)}
                { customItems.length === 0 ? <CustomItemInput mode={"radio"} onAddItem={(item) => addCustomItem(item)}/> : null }
            </RadioGroup>
            : <>
                {props.content.items.map((item) => {
                    return (
                        <div key={item.id} className="flex items-start space-x-2 my-2 ps-2">
                            <Checkbox checked={selected.includes(item.id)} className={"mt-1"}
                                          onCheckedChange={(val) => val ? add(item.id) : remove(item.id)}/>
                            <ListItemContent item={item} />
                        </div>
                    );
                })}
                { customItems.map((item, index) => <div key={index} className="flex items-start space-x-2 my-2 ps-2">
                    <Checkbox checked={true} className={"mt-1"} disabled={true}/>
                    <ListItemContent item={item as Item}/>
                    <Button onClick={() => removeCustomItem(index)} variant={"ghost"} className={"w-6 h-6 p-0.5"}><X /></Button>
                </div>)}
                <CustomItemInput mode={"box"} onAddItem={(item) => addCustomItem(item)}/>
            </>
        return <div>
            {head}
            <p className={"text-muted-foreground ps-2"}>{props.content.prompt}</p>
            {options}
        </div>
    }
}