import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function delay(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(() => resolve(), ms));
}

export function getInitialsForName(name: string): string {
    const split = name.split(" ");
    if (split.length < 2) return split[0].charAt(0).toUpperCase();
    return split[0].charAt(0).toUpperCase() + split[split.length - 1].charAt(0).toUpperCase();
}