import {ContentSpacer} from "../common/layout";
import {Button} from "../../components/ui/button";
import {DialogTrigger} from "../../components/ui/dialog";
import {useOrg} from "../../auth/org-provider";
import CreatePersonDialog from "../../modals/create-person-dialog";
import {Mail, UserPlus, X} from "lucide-react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "../../components/ui/table";
import {useEffect} from "react";

export default function OrgPage() {
    const org = useOrg();

    useEffect(() => { document.title = "Manage Organization - Tekkr" });

    return <ContentSpacer>
        <h1 className={"headline-h1"}>Your Organization: { org.name }</h1>
        <p>
            All members of an organization have full access to the organization's playbooks, ensuring everyone stays aligned and informed.<br />
            Need to bring more team members on board? You can easily add new users to your organization right from this page. Expand your team's capabilities and keep everyone in the loop.
        </p>
        <hr className={"my-4"} />
        <div className={"flex flex-row items-center gap-4"}>
            <h3 className={"headline-h3"}>Members</h3>
            <CreatePersonDialog>
                <DialogTrigger asChild>
                    <Button size={"sm"}><UserPlus className={"w-5 h-5"} /><span className={"ms-2"}>Add someone</span></Button>
                </DialogTrigger>
            </CreatePersonDialog>
        </div>

        <Table>
            <TableRow>
                <TableHead className="w-[200px]">Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead></TableHead>
            </TableRow>
            <TableBody>
                { org.users.map(u => <TableRow key={u.id}>
                    <TableCell>{ u.name }</TableCell>
                    <TableCell>{ u.email }</TableCell>
                    <TableCell className={"flex flex-row items-center gap-2 justify-end"}>
                        <Button variant={"outline-primary"} size={"sm"}><Mail className={"w-5 h-5 me-2"} />Invite</Button>
                        <Button variant={"ghost"} size={"sm"}><X /></Button>
                    </TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    </ContentSpacer>
}