import {ContentSpacer} from "../common/layout";
import {BookMarked, BookOpen} from "lucide-react";
import PlaybookCard from "../../components/shared/playbook-card";
import {useAuth} from "../../auth/auth-provider";
import React, {useEffect} from "react";
import {useQuery} from "@tanstack/react-query";
import {apiClient} from "../../service/tekkr-service";
import {ClientInferResponseBody} from "@ts-rest/core";
import {apiContract} from "tekkr-common/dist/model/api/api.contract";
import LoadingCard from "../../components/shared/loading-card";

const listBlueprintsQuery = {
    queryKey: ["blueprints"],
    queryFn: async () => {
        const response = await (await apiClient).listBlueprints();
        if (response.status === 200) {
            return (response.body as ClientInferResponseBody<typeof apiContract.listBlueprints, 200>).data;
        }
        throw new Error(`unexpected status ${response.status}`);
    }
};

function BlueprintsList() {
    const {isPending, isError, data, error} = useQuery(listBlueprintsQuery);

    if (isPending) {
        return (<div className={"grid md:grid-cols-3 sm:grid-cols-2 gap-3"}>
            {Array.from({length: 4}).map((_, index) => <LoadingCard key={index} />)}
        </div>)
    }

    if (isError) {
        return <div className={"border border-destructive rounded-lg p-4 text-center"}><p
            className={"text-muted-foreground font-semibold"}>Error: {error.message}</p></div>
    }

    // We can assume by this point that `isSuccess === true`
    return (
        <div className={"grid md:grid-cols-3 sm:grid-cols-2 gap-3"}>
            {data.map((blueprint) => (
                <PlaybookCard locked={true} key={blueprint.title} linkTo={`/blueprint/${blueprint.id}`} variant={"vertical"} data={blueprint}/>
            ))}
        </div>
    )
}

function BlueprintFeature() {
    const {isPending, isError, data, error } = useQuery(listBlueprintsQuery);

    if (isPending) {
        return <LoadingCard />
    }

    if (isError) {
        return <div className={"border border-destructive rounded-lg p-4 text-center"}><p className={"text-muted-foreground font-semibold"}>Error: { error.message }</p></div>
    }

    // We can assume by this point that `isSuccess === true`
    const featured = data.find((bp) => bp.id === "insights-gathering-and-feature-requests")!;
    return (<PlaybookCard linkTo={`/blueprint/${featured.id}`} variant={"horizontal"} data={featured}></PlaybookCard>)
}

function DiscoveryPage() {
    const auth = useAuth();

    useEffect(() => { document.title = "Tekkr" });

    return (
        <ContentSpacer>
            <h1 className={"headline-h1 text-center w-full"}>Hey { auth.user?.displayName?.trim().split(" ")[0] }!<br/>What can we help you master today?</h1>
            <p className={"text-center"}>Tekkr Playbooks is a curation of the most advanced ideas, practices and tools
                brought together by Tekkr and other tech organizations to make tech teams work better.</p>

            <div className={"flex flex-row justify-start gap-2 mt-8"}>
                <BookMarked/>
                <h4 className={"headline-h4"}>Your personal playbook awaits!</h4>
            </div>
            <BlueprintFeature />

            <div className={"flex flex-row justify-start gap-2 mt-8"}>
                <BookOpen/>
                <h4 className={"headline-h4"}>Browse All Playbooks</h4>
            </div>
            <BlueprintsList />
        </ContentSpacer>
    )
}

export default DiscoveryPage;