import React from "react";
import {cn} from "../../lib/utils";
import {MoonStar, Sun} from "lucide-react";
import Footer from "../../components/shared/footer";
import { ReactComponent as TekkrScriptLogoDark } from '../../static/tekkr-logo-script-dark.svg';
import { ReactComponent as TekkrScriptLogoLight } from '../../static/tekkr-logo-script-light.svg';
import {useColorScheme} from "../../lib/color-scheme";

const widthClasses = {
    "narrower": "max-w-3xl",
    "default": "max-w-4xl"
}

export function ContentSpacer(props: React.PropsWithChildren & { contentWidth?: keyof typeof widthClasses}) {
    const widthClass = widthClasses[props.contentWidth ?? "default"];
    return (
        <div className={"flex flex-row justify-center py-9 px-12"}>
            <div className={cn("w-full", widthClass)}>
                <div className={"flex flex-col gap-4"}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export function OnboardingPage(props: React.PropsWithChildren) {
    const { isDark, setIsDark } = useColorScheme();
    function toggleDarkMode () {
        setIsDark(!isDark);
    }
    return <div className={"flex flex-col items-center justify-center h-screen"}>
        <div className={"absolute top-0 left-0 right-0 py-4 px-6 flex flex-row justify-between items-center"}>
            <div>
                <TekkrScriptLogoDark className={"hidden dark:block"}/>
                <TekkrScriptLogoLight className={"dark:hidden"}/>
            </div>
            { !isDark ? <MoonStar onClick={toggleDarkMode} className={"cursor-pointer"} /> : <Sun onClick={toggleDarkMode} className={"cursor-pointer"} /> }
        </div>
        <div className={"bg-popover border rounded-lg px-20 py-6 max-w-lg flex flex-col gap-3 items-center"}>
            { props.children }
        </div>
        <div className={"absolute bottom-0 left-0 right-0"}>
            <Footer></Footer>
        </div>
    </div>
}