export interface Config {
    serviceHost: string;
}

const defaultConfig: Config = {
    serviceHost: "https://tekkr-dev.fly.dev"
};

export const config: Config = (() => {
    const isLocal = window.location.host.includes("localhost");
    const local: Partial<Config> = {
        serviceHost: "http://localhost:8000",
    };
    return {
        ...defaultConfig,
        ...(isLocal ? local : undefined),
    }
})();