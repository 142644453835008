import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCa8-xZeUwM2ibd4naQp2WXzVwMAodHlWk",
    authDomain: "app.tekkr.io",
    projectId: "tekkr-io",
    storageBucket: "tekkr-io.appspot.com",
    messagingSenderId: "187030469128",
    appId: "1:187030469128:web:8304c01ff0e63132816fbd",
    measurementId: "G-9GJ7R9BF9G"
};

const app = initializeApp(firebaseConfig);

export function getFirebaseAuth() {
    return getAuth(app);
}