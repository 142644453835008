import React from 'react';
import './App.css';
import Navbar from './components/shared/navbar';
import PlaybookPage from "./pages/playbook/playbook-page";
import Footer from "./components/shared/footer";
import {createBrowserRouter, Outlet, ScrollRestoration} from "react-router-dom";
import {RouterProvider} from "react-router-dom";
import DiscoveryPage from "./pages/discovery/discovery-page";
import LoginPage from "./pages/login/login-page";
import {AuthProvider, RequireAuth} from "./auth/auth-provider";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import OrgPage from "./pages/org/org-page";
import {OrgProvider, RequireOrg} from "./auth/org-provider";
import NoOrgPage from "./pages/no-org/no-org-page";
import InvitePage from "./pages/no-org/invite/invite-page";
import LibraryPage from "./pages/library/library-page";
import BlueprintPage from "./pages/blueprint/blueprint-page";

function AppPage(props: React.PropsWithChildren) {
    return (
        <div className={"min-h-screen flex flex-col"}>
            <ScrollRestoration />
            <Navbar></Navbar>
            { props.children }
            <div className={"footer-spacer flex-grow"}></div>
            <Footer></Footer>
        </div>
    )
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <RequireAuth><OrgProvider><RequireOrg><Outlet /></RequireOrg></OrgProvider></RequireAuth>,
        children: [
            {
                path: "/",
                element: <AppPage><DiscoveryPage></DiscoveryPage></AppPage>
            },
            {
                path: "/playbook/:playbookId",
                element: <AppPage><PlaybookPage></PlaybookPage></AppPage>
            },
            {
                path: "/blueprint/:blueprintId",
                element: <AppPage><BlueprintPage></BlueprintPage></AppPage>
            },
            {
                path: "/org/:orgId",
                element: <AppPage><OrgPage></OrgPage></AppPage>
            },
            {
                path: "/library",
                element: <AppPage><LibraryPage></LibraryPage></AppPage>
            }
        ],
    },
    {
        path: "/invite/:inviteToken",
        element: <RequireAuth><InvitePage></InvitePage></RequireAuth>
    },
    {
        path: "/no-organization",
        element: <OrgProvider><NoOrgPage></NoOrgPage></OrgProvider>
    },
    {
        path: "/login",
        element: <LoginPage></LoginPage>
    }
]);

function App() {
    const [queryClient] = React.useState(() => new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 60 * 1000,
            },
        },
    }));
    return (
      <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <AuthProvider>
                <RouterProvider router={router} />
          </AuthProvider>
      </QueryClientProvider>
  );
}

export default App;
