"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyStage = void 0;
var CompanyStage;
(function (CompanyStage) {
    CompanyStage["seed"] = "seed";
    CompanyStage["seriesA"] = "series_a";
    CompanyStage["seriesB"] = "series_b";
    CompanyStage["seriesC"] = "series_c";
})(CompanyStage || (exports.CompanyStage = CompanyStage = {}));
