import {Button} from "../ui/button";
import {ArrowLeft} from "lucide-react";
import {useNavigate} from "react-router-dom";

function BackButtonRow() {
    const navigate = useNavigate();
    return (
        <div className={"flex justify-start mb-3"}>
            <Button variant={"outline"} onClick={() => navigate(-1)}><ArrowLeft className="h-4 w-4 mr-4"/>Back</Button>
        </div>
    )
}

export default BackButtonRow;