"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentType = void 0;
var ContentType;
(function (ContentType) {
    ContentType["markdown"] = "markdown";
    ContentType["list"] = "list";
    ContentType["peopleList"] = "people_list";
    ContentType["select"] = "select";
    ContentType["section"] = "section";
    ContentType["blueprintReference"] = "blueprint_reference";
    ContentType["externalLink"] = "external_link";
})(ContentType || (exports.ContentType = ContentType = {}));
