import Markdown, {Components} from "react-markdown";
import {ArrowRight, Info} from "lucide-react";
import React from "react";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
import remarkGfm from 'remark-gfm';
import {Table, TableBody, TableCell, TableHeader, TableRow} from "../ui/table";
import {cn} from "../../lib/utils";

const customMarkdownComponents: Record<string, Components["p"]> = {
    "arrow"(props) {
        return <div className={"mb-2 flex flex-row items-start gap-1"}>
            <ArrowRight className={"shrink-0 p-1 inline-block mr-1"} />
            <div>{props.children}</div>
        </div>
    },
    "numbered"(props) {
        return <div className={"mb-2 flex flex-row items-start gap-2"}>
            <div className={"leading-6 shrink-0 inline-block w-6 h-6 rounded-xl bg-accent text-center text-sm font-semibold text-muted-foreground mr-1"}>{ props.id }</div>
            <div>{props.children}</div>
        </div>
    },
    "info"(props) {
        return <div className={"mb-2 flex flex-row items-start gap-1 border-accent border rounded-md p-3 space-x-1 text-muted-foreground text-sm"}>
            <Info className={"shrink-0 p-1 -mt-0.5"} />
            <div>{props.children}</div>
        </div>
    },
}

const markdownComponents: Components = {
    h1(props) {
        return <h1 className={"headline-h1"}>{props.children}</h1>
    },
    h3(props) {
        return <h3 className={"headline-h3"}>{props.children}</h3>
    },
    h4(props) {
        return <h4 className={"headline-h4"}>{props.children}</h4>
    },
    table(props) {
        if (!props.children) {
            return <></>
        }
        const firstRow = (props.children as any[])[1].props.children[0].props.children;
        const width = firstRow.length <= 4 ? 100 : 30 * firstRow.length;
        return <div className={"overflow-x-scroll"}>
            <div style={{width: `${width}%`}}>
                <Table>{props.children}</Table>
            </div>
        </div>
    },
    li(props) {
        return <li className={"mb-2 flex flex-row items-start gap-2"}>
            <div>&bull;</div>
            <div>{props.children}</div>
        </li>
    },
    thead(props) {
        return <TableHeader>{ props.children }</TableHeader>
    },
    tbody(props) {
        return <TableBody>{ props.children }</TableBody>
    },
    tr(props) {
        return <TableRow>{ props.children }</TableRow>
    },
    td(props) {
        return <TableCell>{ props.children }</TableCell>
    },
    a(props) {
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        return <a className={"underline"} {...props}></a>
    }
};

const allMarkdownComponents = {
    ...markdownComponents,
    ...customMarkdownComponents,
}

function TekkrMarkdown(props: { markdown: string, className?: string }) {
    return <div className={cn(props.className, "flex flex-col gap-3 w-full")}>
        <Markdown
            remarkPlugins={[remarkDirective, remarkDirectiveRehype, remarkGfm]}
            components={allMarkdownComponents}>
            {props.markdown}
        </Markdown>
    </div>
}

export default TekkrMarkdown;