import {PlaybookBlueprint} from "tekkr-common/dist/model/playbook/blueprint";
import {
    BlueprintReferenceContentBlueprint
} from "tekkr-common/dist/model/playbook/step/content/types/blueprint-reference/blueprint";
import {ArrowRight, Book} from "lucide-react";
import {Link} from "react-router-dom";
import {Button} from "../../../../../ui/button";

export default function BlueprintReferenceStepContentView(props: { step: PlaybookBlueprint["steps"][0], content: BlueprintReferenceContentBlueprint }) {
    return <div className={"border border-accent rounded-md px-4 py-3 flex flex-row items-center justify-between mt-6"}>
        <div className={"flex flex-row items-center justify-start gap-3 text-sm"}>
            <div className={"bg-primary rounded-full w-10 h-10 flex flex-col justify-center items-center"}>
                <Book className={"w-5 h-5"}/>
            </div>
            <div>
                <div>Playbook: How To Create a Quarterly Retrospective Meeting</div>
                <div className={"text-muted-foreground"}>some more information</div>
            </div>
        </div>
        <Button asChild>
            <Link to={`/blueprint/${props.content.blueprintId}`}>
                <div className={"flex flex-row gap-2 items-center"}>
                    Learn more
                    <ArrowRight className={"w-5 h-5"}/>
                </div>
            </Link>
        </Button>
    </div>
}