import React from "react";
import {PlaybookBlueprint} from "tekkr-common/dist/model/playbook/blueprint";
import MarkdownStepContentView from "./types/markdown";
import ListStepContentView from "./types/list";
import PeopleListStepContentView from "./types/people-list";
import {ContentEdit} from "tekkr-common/dist/model/playbook/step/content/edit";
import {ContentType} from "tekkr-common/dist/model/playbook/step/content/blueprint";
import SelectStepContentView from "./types/select";
import SectionStepContentView from "./types/section";
import BlueprintReferenceStepContentView from "./types/blueprint-reference";
import ExternalLinkStepContentView from "./types/external-link";

export function StepContentView (props: { step: PlaybookBlueprint["steps"][0]; content: PlaybookBlueprint["steps"][0]["content"][0]; contentEdit?: ContentEdit, isEditing: boolean }) {
    if (props.content.type === "markdown") {
        return <MarkdownStepContentView step={props.step} content={props.content} contentEdit={props.contentEdit} isEditing={props.isEditing} />;
    } else if (props.content.type === "list") {
        return <ListStepContentView step={props.step} content={props.content} contentEdit={props.contentEdit} isEditing={props.isEditing} />
    } else if (props.content.type === ContentType.peopleList) {
        return <PeopleListStepContentView isEditing={props.isEditing} step={props.step} content={props.content} contentEdit={props.contentEdit}></PeopleListStepContentView>
    } else if (props.content.type === ContentType.select) {
        return <SelectStepContentView step={props.step} content={props.content} contentEdit={props.contentEdit}></SelectStepContentView>
    } else if (props.content.type === ContentType.section) {
        return <SectionStepContentView content={props.content} />
    } else if (props.content.type === ContentType.blueprintReference) {
        return <BlueprintReferenceStepContentView step={props.step} content={props.content} />
    } else if (props.content.type === ContentType.externalLink) {
        return <ExternalLinkStepContentView step={props.step} content={props.content} />
    }
    return <div></div>
}