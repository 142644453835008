import TekkrMarkdown from "../tekkr-markdown";
import React, {createContext, useRef} from "react";
import {PlaybookBlueprint} from "tekkr-common/dist/model/playbook/blueprint";
import {PlaybookEdit} from "tekkr-common/dist/model/playbook/edit";
import PlaybookStep from "./components/step";

interface Props {
    blueprint: PlaybookBlueprint;
    playbookEdit?: PlaybookEdit
    isEditing: boolean;
}


interface PlaybookViewController {
    goToStep(index: number): void;
}

export const PlaybookViewControllerContext = createContext<PlaybookViewController>({} as PlaybookViewController);

export default function PlaybookView (props: React.PropsWithChildren & Props) {
    const stepContainerRef = useRef<HTMLDivElement>(null);
    const controller: PlaybookViewController = {
        goToStep(index: number) {
            console.log(`scrolling to ${index}`);
            stepContainerRef.current?.children[index].scrollIntoView({ behavior: "smooth" });
        }
    }

    return <PlaybookViewControllerContext.Provider value={controller}>
        <div className={"flex flex-col gap-4"}>
            <TekkrMarkdown markdown={props.blueprint.introduction}/>
            <br/>
            <div ref={stepContainerRef} className={"flex flex-col gap-4"}>
                {props.blueprint.steps.map((step, index) => {
                    if (step.id && props.playbookEdit && !props.playbookEdit.steps![step.id]) {
                        props.playbookEdit.steps![step.id] = {};
                    }
                    return <PlaybookStep key={step.id ?? index} index={index} step={step} isEditing={props.isEditing}
                                         stepEdit={props.playbookEdit?.steps?.[step.id]}></PlaybookStep>
                })}
            </div>
        </div>
    </PlaybookViewControllerContext.Provider>
}