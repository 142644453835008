import {Navigate, useNavigate, useParams} from "react-router-dom";
import {OnboardingPage} from "../../common/layout";
import {Button} from "../../../components/ui/button";
import {useAuth} from "../../../auth/auth-provider";
import {useMutation, useQuery} from "@tanstack/react-query";
import {apiClient, ApiError} from "../../../service/tekkr-service";
import {ClientInferResponseBody} from "@ts-rest/core";
import {apiContract} from "tekkr-common/dist/model/api/api.contract";
import LoadingCard from "../../../components/shared/loading-card";
import {DateTime} from "luxon";

export default function InvitePage() {
    const params = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();

    if (!params.inviteToken || params.inviteToken === "") {
        throw new Error("missing token");
    }
    const token = params.inviteToken;

    const { isPending, data, isError } = useQuery({
        queryKey: ["invite", token],
        queryFn: async () => {
            const response = await (await apiClient).getInvite({ params: { token } });
            return response.body as ClientInferResponseBody<typeof apiContract.getInvite, 200>;
        },
        retry: (count, e) => {
            if (e instanceof ApiError && e.status === 404) {
                return false;
            }
            return count < 4;
        }
    })

    const mutation = useMutation({
        mutationFn: async () => {
            await (await apiClient).acceptInvite({
                params: { token }
            });
            navigate("/", { replace: true });
        }
    });

    if (isError) {
        return <Navigate to={"/404"} />
    }

    return <OnboardingPage>
        { isPending ? <LoadingCard /> : null }
        {!!data ? <div className={"flex flex-col gap-6"}>
            <h1 className={"headline-h1"}>Join Organization</h1>
            <p>Hey {user.displayName},<br/>Do you want to join the organization <span className={"font-semibold"}>"{data.orgName}"</span>?</p>
            <Button onClick={() => mutation.mutate()} disabled={mutation.isPending} className={"w-full"}>Join</Button>
            <div className={"text-sm"}>This link is valid until { DateTime.fromISO(data.validUntil).toLocaleString({ month: "long", day: "numeric"}) }</div>
        </div> : null}
    </OnboardingPage>
}