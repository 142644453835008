"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookCategoryConfig = exports.PlaybookCategory = void 0;
var PlaybookCategory;
(function (PlaybookCategory) {
    PlaybookCategory["product_management"] = "product_management";
    PlaybookCategory["people_management"] = "people_management";
    PlaybookCategory["stakeholder_management"] = "stakeholder_management";
    PlaybookCategory["sdlc"] = "sdlc";
    PlaybookCategory["target_setting"] = "target_setting";
})(PlaybookCategory || (exports.PlaybookCategory = PlaybookCategory = {}));
exports.playbookCategoryConfig = {
    [PlaybookCategory.people_management]: { color: "#483263", title: "People Management" },
    [PlaybookCategory.product_management]: { color: "#80423E", title: "Product Management" },
    [PlaybookCategory.sdlc]: { color: "#3B6495", title: "SDLC" },
    [PlaybookCategory.stakeholder_management]: { color: "#7C6351", title: "Stakeholder Management" },
    [PlaybookCategory.target_setting]: { color: "#5B7F8C", title: "Target Setting" },
};
