import { useLocalStorage } from "@uidotdev/usehooks";
import {
    useEffect,
    useMemo
} from "react";
import { useMediaQuery } from "react-responsive";

export function useColorScheme() {
    const [isDark, setIsDark] = useLocalStorage<boolean>("dark-mode", false);

    const systemPrefersDark = useMediaQuery(
        {
            query: "(prefers-color-scheme: dark)",
        },
        undefined
    );

    const value = useMemo(
        () => (isDark === undefined ? systemPrefersDark : isDark),
        [isDark, systemPrefersDark]
    );

    useEffect(() => {
        if (value) {
            document.body.parentElement!.classList.add('dark');
        } else {
            document.body.parentElement!.classList.remove('dark');
        }
    }, [value]);

    return {
        isDark: value,
        setIsDark,
    };
}
