import React, {useContext} from "react";
import TekkrMarkdown from "../../../../../shared/tekkr-markdown";
import {MarkdownContentBlueprint} from "tekkr-common/dist/model/playbook/step/content/types/markdown/blueprint";
import {PlaybookBlueprint} from "tekkr-common/dist/model/playbook/blueprint";
import '@mdxeditor/editor/style.css'
import {PlaybookPageContext} from "../../../../../../pages/playbook/playbook-page";
import {MarkdownContentEdit} from "tekkr-common/dist/model/playbook/step/content/types/markdown/edit";
import {ContentEdit} from "tekkr-common/dist/model/playbook/step/content/edit";


export default function MarkdownStepContentView(props: { step: PlaybookBlueprint["steps"][0], content: MarkdownContentBlueprint, contentEdit?: ContentEdit, isEditing: boolean }) {
    const edit = props.contentEdit as MarkdownContentEdit | undefined;

    const { controller } = useContext(PlaybookPageContext);

    /*const onChange = (markdown: string) => {
        if (edit) {
            edit.markdown = markdown;
            controller.editUpdated();
        }
    }*/

    return <div className={"flex flex-row justify-between w-full items-center"}>
        <TekkrMarkdown markdown={edit?.markdown ?? props.content.markdown}></TekkrMarkdown>
    </div>
    /*if (!props.isEditing) {
        return <div className={"flex flex-row justify-between w-full items-center py-2"}>
            <TekkrMarkdown markdown={edit.markdown ?? props.content.markdown}></TekkrMarkdown>
        </div>
    } else {
        return <MDXEditor className={"text-foreground"} onChange={onChange} markdown={edit.markdown ?? props.content.markdown} plugins={[headingsPlugin()]} />
    }*/
}