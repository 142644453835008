"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiContract = void 0;
const core_1 = require("@ts-rest/core");
const zod_1 = require("zod");
const base_1 = require("../playbook/base");
const c = (0, core_1.initContract)();
exports.apiContract = c.router({
    getAccount: {
        method: "GET",
        path: "/account/",
        responses: {
            200: zod_1.z.object({
                uid: zod_1.z.string(),
                orgs: zod_1.z.array(zod_1.z.object({
                    id: zod_1.z.string(),
                    name: zod_1.z.string()
                })),
            }),
        },
        summary: "Get the current user's account",
    },
    listBlueprints: {
        method: "GET",
        path: "/blueprint/",
        responses: {
            200: zod_1.z.object({
                data: zod_1.z.array(base_1.basePlaybookSchema)
            })
        },
        summary: "List all available blueprints",
    },
    getBlueprintById: {
        method: "GET",
        path: "/blueprint/:id",
        responses: {
            200: zod_1.z.any(),
        },
        summary: "Get a playbook blueprint by id",
    },
    getPlaybookById: {
        method: "GET",
        path: "/playbook/:playbookId",
        responses: {
            200: zod_1.z.object({
                data: zod_1.z.object({
                    id: zod_1.z.string(),
                    createdBy: zod_1.z.string(),
                    blueprintId: zod_1.z.string(),
                    edit: zod_1.z.any(),
                })
            }),
        },
        summary: "Get a playbook by id",
    },
    updatePlaybookEdit: {
        method: "PUT",
        path: "/playbook/:playbookId/edit",
        responses: {
            200: zod_1.z.object({
                ok: zod_1.z.boolean(),
            })
        },
        body: zod_1.z.any(),
        summary: "Update the edit of a playbook",
    },
    createPlaybook: {
        method: "POST",
        path: "/playbook/",
        body: zod_1.z.object({
            orgId: zod_1.z.string(),
            blueprintId: zod_1.z.string(),
            title: zod_1.z.string().min(2),
        }),
        responses: {
            201: zod_1.z.object({
                id: zod_1.z.string(),
            }),
        },
    },
    createOrg: {
        method: "POST",
        path: "/org/",
        responses: {
            201: zod_1.z.object({
                id: zod_1.z.string(),
                name: zod_1.z.string(),
            })
        },
        body: zod_1.z.object({
            name: zod_1.z.string().min(2),
        }),
    },
    createOrgUser: {
        method: "POST",
        path: "/org/:orgId/user",
        responses: {
            201: zod_1.z.object({
                name: zod_1.z.string(),
                id: zod_1.z.string(),
            }),
        },
        body: zod_1.z.object({
            name: zod_1.z.string().min(2),
            email: zod_1.z.string().email(),
        }),
        summary: "Create a new user in a specific organization",
    },
    getOrgById: {
        method: "GET",
        path: "/org/:orgId",
        responses: {
            200: zod_1.z.object({
                id: zod_1.z.string(),
                name: zod_1.z.string(),
                users: zod_1.z.array(zod_1.z.object({
                    name: zod_1.z.string(),
                    email: zod_1.z.string(),
                    id: zod_1.z.string(),
                    hasAccount: zod_1.z.boolean(),
                })),
            }),
        },
        summary: "Get an organization by its id",
    },
    createUserInvite: {
        method: "PUT",
        path: "/org/:orgId/user/:userId/invite",
        responses: {
            201: zod_1.z.object({
                token: zod_1.z.string(),
                validUntil: zod_1.z.date(),
            })
        },
        body: zod_1.z.any(),
        summary: "Create a invite for a user to join the org with their account",
    },
    acceptInvite: {
        method: "GET",
        path: "/org/invite/:token/accept",
        responses: {
            200: zod_1.z.object({
                ok: zod_1.z.boolean(),
            }),
        },
        summary: "Accept an invite to join an organization with the users account",
    },
    getInvite: {
        method: "GET",
        path: "/org/invite/:token",
        responses: {
            200: zod_1.z.object({
                orgName: zod_1.z.string(),
                validUntil: zod_1.z.string(),
            }),
        },
        summary: "Get context about an invite",
    },
    listPlaybooks: {
        method: "GET",
        path: "/playbook",
        query: zod_1.z.object({
            org: zod_1.z.string(),
            blueprintId: zod_1.z.string().optional(),
        }),
        responses: {
            200: zod_1.z.object({
                data: zod_1.z.array(zod_1.z.intersection(base_1.basePlaybookSchema, zod_1.z.object({
                    progress: zod_1.z.number(),
                    createdAt: zod_1.z.string(),
                })))
            }),
        },
        summary: "Fetch the playbooks in a account",
    },
}, {
    pathPrefix: "/api",
});
