import { Button } from "../../components/ui/button";
import {Avatar, AvatarImage} from "../../components/ui/avatar";
import React from "react";
import {Calendar} from "lucide-react";
import {Navigate, useNavigate} from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {getFirebaseAuth} from "../../firebase";
import {OnboardingPage} from "../common/layout";
import {useAuthController} from "../../auth/auth-provider";

function LoginPage() {
    const navigate = useNavigate();

    const firebaseAuth = getFirebaseAuth();
    const authController = useAuthController();

    if (authController.auth) {
        return <Navigate to={"/"} />
    }

    async function googleLogin() {
        await signInWithPopup(firebaseAuth, new GoogleAuthProvider());
        await firebaseAuth.authStateReady();
        await authController.refetch();
        navigate("/", { replace: true });
    }

    return <OnboardingPage>
        <h1 className={"headline-h1 text-center"}>Login to Tekkr</h1>
        <div className={"flex flex-col gap-3 w-full mt-4"}>
            <Button onClick={() => googleLogin()} className={"w-full"} size={"sm"}>Sign in with Google</Button>
            <Button variant={"secondary"} className={"w-full hidden"} size={"sm"}>Sign in with Microsoft</Button>
            <div className={"text-muted-foreground text-center text-sm px-8 mt-4"}>By clicking continue, you agree to
                our Terms of Service
                and Privacy Policy.
            </div>
            <div className={"flex flex-row items-center gap-4 mt-6"}>
                <hr className={"flex-grow"}/>
                <div className={"text-muted-foreground text-xs font-semibold"}>No account yet?</div>
                <hr className={"flex-grow"}/>
            </div>
            <div className={"flex flex-row justify-center"}>
                <Avatar className={"border-background border-2"}>
                    <AvatarImage
                        src="https://media.licdn.com/dms/image/v2/D4E03AQHN9rU7J2F32g/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1718321575391?e=1733356800&v=beta&t=HO9d6CxI2w-vSIoqZ3te0B1mxKuwqHKe5cn6ZvE__bs"
                        alt="@filo"/>
                </Avatar>
                <Avatar className={"-mx-2 border-background border-2"}>
                    <AvatarImage
                        src="https://media.licdn.com/dms/image/v2/D4D03AQESLo7sCGB_QA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1696340328667?e=1733356800&v=beta&t=ne-CqCS7ZlNfa75P2FVZ3wehF5zCIEWDfNt5-A1V3NA"
                        alt="@tomi"/>
                </Avatar>
                <Avatar className={"border-background border-2"}>
                    <AvatarImage
                        src="https://media.licdn.com/dms/image/v2/C4E03AQEcPWm0fTepmA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1657547204289?e=1733356800&v=beta&t=J9USizrSIG6A5a1fM4t8l0Hp6AjcRqzaNU_Rfw02rXU"
                        alt="@philipp"/>
                </Avatar>
            </div>
            <p className={"text-sm text-center"}>Request early access and try Tekkr for 30 days.</p>
            <div className={"flex flex-col items-center"}>
                <Button variant={"secondary"}><Calendar className={"w-4 h-4 mr-2"}/>Schedule a call</Button>
            </div>
        </div>
    </OnboardingPage>
}

export default LoginPage;