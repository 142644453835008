import { Star } from "lucide-react";
import {cn} from "../../lib/utils";

function Rating (props: { rating: number }) {
    return (
        <div className={"flex flex-row gap-0.5"}>
            { Array.from({ length: 5 }).map((_, pos) => {
                const filled = Math.round(props.rating) > pos;
                return (
                    <Star key={pos} fill={filled ? "rgb(var(--foreground))" : "rgba(0,0,0,0)"} className={cn("w-4 h-4", filled ? "text-foreground" : "text-muted-foreground")} />
                )
            })}
        </div>
    )
}
export default Rating;