import {useState} from "react";

export function useIdentifierList(initial?: string[], sideEffect?: (value: string[]) => void): [string[], (toAdd: string) => void, (toRemove: string) => void, (newVal: string[]) => void] {
    const [selected, setSelected] = useState<string[]>(initial ?? []);
    function remove(pId: string) {
        const newVal = selected.filter(p => p !== pId);
        setSelected(newVal);
        if (sideEffect) {
            sideEffect(newVal);
        }
    }
    function add(pId: string) {
        const newVal = [...selected, pId];
        setSelected(newVal);
        if (sideEffect) {
            sideEffect(newVal);
        }
    }
    function replace(newVal: string[]) {
        setSelected(newVal);
        if (sideEffect) {
            sideEffect(newVal);
        }
    }
    return [selected, add, remove, replace];
}