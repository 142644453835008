import {ContentSpacer} from "../common/layout";
import PlaybookCard from "../../components/shared/playbook-card";
import React, {useEffect} from "react";
import {useQuery} from "@tanstack/react-query";
import {apiClient} from "../../service/tekkr-service";
import {ClientInferResponseBody} from "@ts-rest/core";
import {apiContract} from "tekkr-common/dist/model/api/api.contract";
import {useOrg} from "../../auth/org-provider";
import LoadingCard from "../../components/shared/loading-card";
import {Link} from "react-router-dom";

const listPlaybooksQuery = (orgId: string) => ({
    queryKey: ["library-playbooks", orgId],
    queryFn: async () => {
        const response = await (await apiClient).listPlaybooks({ query: { org: orgId } });
        if (response.status === 200) {
            return (response.body as ClientInferResponseBody<typeof apiContract.listPlaybooks, 200>).data;
        }
        throw new Error(`unexpected status ${response.status}`);
    }
});

function PlaybookList () {
    const org = useOrg();
    const {isPending, isError, data, error} = useQuery(listPlaybooksQuery(org.id));

    if (isPending) {
        return (<div className={"grid md:grid-cols-3 sm:grid-cols-2 gap-3"}>
            {Array.from({length: 4}).map((_, index) => <LoadingCard key={index} />)}
        </div>)
    }

    if (isError) {
        return <div className={"border border-destructive rounded-lg p-4 text-center"}><p
            className={"text-muted-foreground font-semibold"}>Error: {error.message}</p></div>
    }

    if (data.length === 0) {
        // empty state
        return <div className={"border rounded-lg p-6 text-center mt-6"}>
            <h4 className={"headline-h4 mb-2"}>You don't have any playbooks yet.</h4>
            <p>You can find some to get started on our <Link to={"/"} className={"text-primary font-semibold"}>discovery page</Link>.</p>
        </div>
    }

    // We can assume by this point that `isSuccess === true`
    return (
        <div className={"grid md:grid-cols-3 sm:grid-cols-2 gap-3"}>
            {data!.map((playbook) => (
                <PlaybookCard key={playbook.id} variant={"vertical"} linkTo={`/playbook/${playbook.id}`} progress={playbook.progress} data={playbook}/>
            ))}
        </div>
    )
}

export default function LibraryPage () {
    useEffect(() => { document.title = "My Playbooks - Tekkr" });
    return <ContentSpacer>
        <h1 className={"headline-h1"}>Your playbooks</h1>
        <PlaybookList></PlaybookList>
    </ContentSpacer>
}